/* Box sizing rules */
*,
*::before,
*::after {
   box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
   margin: 0;
}

/*  permission to use hard wraps when no soft wrap opportunties */

p,
h1,
h2,
h3,
h4,
h5,
h6 {
   overflow-wrap: break-word;
}

ul,
ol {
   list-style: none;
   padding: 0;
   margin: 0;
}

/* Set core root defaults */
html:focus-within {
   scroll-behavior: smooth;
}

/* Set core body defaults */
body {
   min-height: 100vh;
   text-rendering: optimizeSpeed;
   line-height: 1.5;
   overflow-x: hidden;
   -webkit-font-smoothing: antialiased;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
   text-decoration-skip-ink: auto;
}

/* Make images easier to work with */

img,
picture,
video,
canvas,
svg {
   display: block;
   max-width: 100%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
   font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
   html:focus-within {
      scroll-behavior: auto;
   }
   *,
   *::before,
   *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
   }
}
