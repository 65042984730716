@use 'abstracts' as *;

.about {
   @include e(body) {
      @include e(img) {
         transform: rotateY(180deg);
      }

      @include mq(min-width, tablet-home) {
         gap: var(--gap-offset);
         @include e(img) {
            @include bg-img(
               '../assets/images/homepage/desktop/about.jpg',
               $position: 75%,
               $size: cover
            );
            & > img {
               display: none;
            }
         }
      }

      @include mqs(min-width, tablet-home, max-width, desktop) {
         grid-template-columns: 320px auto;
         grid-template-rows: minmax(630px, auto);
         @include e(img) {
            background-position: 71% 100%;
         }
      }

      @include mq(min-width, desktop) {
         @include e(img) {
            background-position: 90%;
         }
      }

      @include e(txt) {
         padding: 2rem 0 3.5rem;
         & .desc {
            @include mq(max-width, tablet) {
               margin-bottom: 2rem;
            }
            @include mq(min-width, desktop) {
               max-width: 370px;
               margin-bottom: 3rem;
            }
         }
      }
   }
}
