@use 'abstracts' as *;

.details {
   // body tag
   @include e(body) {
      gap: var(--gap-details);
      margin-bottom: 6rem;

      @include mq(min-width, desktop) {
         grid-template-columns: repeat(5, 1fr);
         grid-template-rows: repeat(4, auto);

         @include e(background) {
            grid-column: 3 / -1;
         }
      }

      @include e(hero) {
         align-content: center;
         justify-items: left;
         padding: var(--ct-padding-details-hero);
         & .desc {
            margin-bottom: var(--details-hero-desc);
            @include mqs(min-width, tablet, max-width, desktop) {
               grid-column: 2 / -1;
               grid-row: 1 / 4;
            }
         }

         & .btn {
            align-self: flex-start;
         }

         @include e(skills) {
            max-width: 350px;
         }

         @include mqs(min-width, tablet, max-width, desktop) {
            grid-template-columns: repeat(2, 1fr);
            --gap: 1.2rem 3rem;
         }

         @include mq(min-width, desktop) {
            grid-column: 1 / 3;
            grid-row: 1 / 3;
         }
      }

      @include e(background) {
         & h2 {
            margin-bottom: 2rem;
         }
         @include mq(min-width, desktop) {
            grid-row: 1 / 3;
         }
      }

      @include e(previews) {
         --gap: 2.5rem;
         @include mq(min-width, desktop) {
            grid-column: 3 / -1;
            grid-row: 2 / -1;
         }
      }
   }
}
