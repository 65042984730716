@use 'abstracts' as *;

.header {
   @include m(pm) {
      padding-block: var(--ct-padding-header);

      @include e(body) {
         @include flexy($just: space-between, $align: center);
         position: relative;
      }
   }
}
