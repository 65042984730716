@use 'abstracts' as *;

.grid-ct-offset {
   @include mq(max-width, tablet) {
      grid-template-columns: 1fr;
   }

   @include mq(min-width, tablet) {
      @include e(img) {
         background-position: center;
         background-size: cover;
      }

      @include e(txt) {
         padding: 2rem 0 3.5rem;
         display: grid;
         align-content: center;
         justify-items: left;
      }
   }

   @include mq(min-width, desktop) {
      grid-template-columns: minmax(500px, 1fr) auto;
   }

   @include e(txt) {
      & .desc {
         @include mqs(min-width, tablet, max-width, desktop) {
            margin-bottom: 2rem;
         }
      }
   }
}
