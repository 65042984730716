@use 'abstracts' as *;

.grid-ct {
   @include m(contact) {
      padding: var(--ct-contact);
      gap: var(--gap-contact);
      @include mq(min-width, desktop) {
         grid-template-columns: repeat(3, 1fr);
      }
   }
}

.get-in-touch {
   @include e(body) {
      & .desc {
         margin-bottom: var(--contact-get-in-touch);
      }

      @include e(info) {
         margin-bottom: 1.5rem;
         & strong {
            padding-right: 0.5rem;
         }
         & a {
            color: inherit;
            &:hover {
               text-decoration: underline;
            }
         }
      }

      @include mq(min-width, desktop) {
         grid-column: 2 / -1;
      }
   }
}

.contact-me {
   margin-bottom: 4rem;
   @include e(title) {
      & .title {
         @include mq(min-width, tablet) {
            margin-bottom: 3rem;
         }
      }
   }

   @include e(form) {
      @include mq(min-width, desktop) {
         grid-column: 2 / -1;
      }
   }
}
