@use 'abstracts' as *;

.grid-ct {
   display: grid;
   gap: var(--gap, 2rem);
}

.justify-right {
   @include mq(min-width, desktop) {
      display: grid;
      justify-items: right;
   }
}
