@use 'abstracts' as *;

.logo {
   cursor: pointer;
   transform-origin: center;
   transition: fill var(--btn-hover-transition), transform 0.5s;

   @include m(ct) {
      &:hover .logo {
         fill: hsl(var(--clr-pm-cyan));
         transform: rotateX(180deg);
      }
   }
}
