@use 'abstracts' as *;

.btn {
   display: inline-block;
   padding: 0.6rem 2.4rem;
   @include uc();
   font-size: 0.8rem;
   line-height: var(--lh-100);
   transition: background-color var(--btn-hover-transition),
      color var(--btn-hover-transition);

   @include m(pm) {
      position: relative;
      padding-left: 4.5rem;
      font-weight: var(--fw-medium);
      background-color: hsl(var(--clr-pm-dark-blue));
      color: hsl(var(--clr-sd-ultra-light-grey));
      @include p-el(before, $height: 100%, $width: 40px) {
         @include position(absolute) {
            inset: 0;
         }
         display: block;
         background-color: rgba(black, 0.15);
      }

      @include e(arrows) {
         @include position(absolute) {
            inset: 50% 0 0 11.5px;
         }
         transform: translateY(-50%);
         @include m(arrow) {
            transition: stroke var(--btn-hover-transition);
         }
      }

      @include m(disabled) {
         pointer-events: none;
         background-color: hsl(var(--clr-sd-light-grey));
         @include p-el(before) {
            opacity: 0.1;
         }
         & .btn--pm__arrows--arrow {
            stroke: hsl(var(--clr-pure-white));
         }
      }

      &:hover {
         background-color: hsl(var(--clr-pm-cyan));
         & .btn--pm__arrows--arrow {
            stroke: hsl(var(--clr-pure-white));
         }
      }
   }

   @include m(sd) {
      border: 1.5px solid hsl(var(--clr-pm-dark-grey));
      color: hsl(var(--clr-pm-dark-grey));
      font-weight: var(--fw-semi-bold);
      &:hover {
         background-color: hsl(var(--clr-pm-dark-grey));
         color: hsl(var(--clr-pure-white));
      }

      @include m(disabled) {
         opacity: 0.3;
         pointer-events: none;
      }
   }

   @include m(contact) {
      background-color: hsl(var(--clr-pm-dark-blue));
      color: hsl(var(--clr-pure-white));
      &:hover {
         background-color: hsl(var(--clr-pm-cyan));
      }
   }
}
