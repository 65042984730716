@use 'abstracts' as *;

.hamburger {
   @include e(btn) {
      @include e(lines) {
         position: relative;
         height: 24px;
         width: 33px;

         @include e(line) {
            position: absolute;
            left: 0;
            height: 4px;
            width: 100%;
            background-color: hsl(var(--clr-pm-dark-grey));
            transform-origin: center center 0px;
            transition: all 0.3s ease;

            &:nth-child(1) {
               top: 0;
               .active & {
                  transform: rotate(45deg) translate(7px, 7px);
               }
            }

            &:nth-child(2) {
               top: calc(50% - 2px);
               .active & {
                  opacity: 0;
               }
            }

            &:nth-child(3) {
               bottom: 0;
               .active & {
                  transform: rotate(-45deg) translate(7px, -7px);
               }
            }
         }
      }
   }

   @include mq(min-width, tablet) {
      display: none;
   }
}
