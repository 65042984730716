@use 'abstracts' as *;

.nav-pm-ct {
   @include mq(max-width, tablet) {
      @include position(absolute, $z-index: 999) {
         top: 85px;
         right: 0;
      }
      transform: translateX(120%);
      background-color: hsl(var(--clr-pm-dark-grey));
      transition: transform 0.5s cubic-bezier(0.04, -0.03, 0.52, 1.49);
   }
   &[data-visible='true'] {
      transform: translateX(0);
   }
}

.nav {
   & a {
      @include uc();
      transition: color var(--btn-hover-transition);
      &:hover {
         color: hsl(var(--clr-pm-cyan));
      }
   }

   @include e(list) {
      @include mq(min-width, tablet) {
         @include flexy($align: center);
         gap: 3rem;
      }

      @include m(sd) {
         @include mq(max-width, tablet) {
            @include flexy($dir: column, $align: center);
            gap: 1.8rem;
            margin-bottom: 1rem;
         }
      }
   }

   @include m(pm) {
      & a {
         @include mq(max-width, tablet) {
            display: block;
            width: 100%;
            padding: 0.2rem 6rem;
            color: hsl(var(--clr-sd-ultra-light-grey));
            &:first-child {
               padding-top: 2rem;
            }
            &:last-child {
               padding-bottom: 2rem;
            }
         }

         @include mq(min-width, tablet) {
            position: relative;
            @include p-el(after, $width: 100%, $height: 3px) {
               display: block;
               @include position(absolute) {
                  bottom: -5px;
                  left: 0;
               }
               background-color: hsl(var(--clr-pm-cyan));
               transform-origin: center;
               transform: scale(0);
               transition: transform 0.2s linear;
            }
            &:hover {
               color: hsl(var(--clr-pm-dark-grey));
               @include p-el(after) {
                  transform: scale(1);
               }
            }
         }
      }
   }

   @include m(tr) {
      position: relative;
      grid-template-columns: repeat(2, 1fr);
      --gap: 0;

      @include e(link) {
         padding: var(--ct-nav-projects);
         transition: background-color var(--btn-hover-transition),
            color var(--btn-hover-transition);

         @include p-el(after, $content: 'Previous Project') {
            @include position(absolute, $z-index: 99) {
               bottom: 15px;
            }
            font-size: 1.2rem;
            color: hsl(var(--clr-pm-dark-grey));
            transition: color var(--btn-hover-transition);
         }

         @include e(arrow) {
            @include position(absolute) {
               top: 20px;
               @include mq(min-width, tablet) {
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  @include m(right) {
                     left: unset;
                     right: 0;
                  }
               }
            }
         }

         & .arrow {
            transition: stroke var(--btn-hover-transition);
         }

         &:hover {
            background-color: hsl(var(--clr-pm-dark-grey));
            color: hsl(var(--clr-sd-ultra-light-grey));
            & .arrow {
               stroke: hsl(var(--clr-sd-ultra-light-grey));
            }
         }
         &:hover::after {
            color: hsl(var(--clr-sd-ultra-light-grey));
         }

         &:last-child {
            display: grid;
            justify-items: right;
            align-items: center;
            border-left: var(--thin-line);
            @include p-el(after, $content: 'Next Project') {
               @include mq(min-width, tablet) {
                  right: 32px;
               }
            }
         }
      }
   }
}
