@use 'abstracts' as *;

.project {
   @include e(img) {
      & > a {
         transition: transform 0.3s ease-in-out;
         &:hover {
            transform: translateY(-3%);
         }
      }
   }

   @include mq(min-width, tablet) {
      gap: var(--gap-offset);
      @include e(img) {
         display: grid;
         place-items: center;
      }
   }

   @include mqs(min-width, tablet, max-width, desktop) {
      grid-template-columns: repeat(2, 1fr);
   }

   @include e(txt) {
      @include e(links) {
         @include flexy($align: center);
         flex-wrap: wrap;
         gap: 1rem;
      }

      @include mq(max-width, tablet) {
         padding-block: 1.7rem;
         & .desc {
            margin-bottom: 4rem;
         }
      }

      @include mq(min-width, desktop) {
         & .desc {
            margin-bottom: 2rem;
         }
      }
   }

   @include m(right) {
      @include mq(min-width, tablet) {
         & .project__img {
            grid-column: 2 / 3;
            grid-row: 1 / -1;
         }
         & .project__txt {
            grid-column: 1 / 2;
            grid-row: 1 / -1;
         }
      }

      @include mq(min-width, desktop) {
         grid-template-columns: auto minmax(500px, 1fr);
      }
   }
}
