@use 'abstracts' as *;

.input {
   width: 100%;
   padding: 0.8rem 1.2rem;
   border: 1.5px solid transparent;
   background-color: hsl(var(--clr-sd-light-grey));
   transition: border var(--btn-hover-transition);
   &::placeholder {
      color: hsl(var(--clr-pm-dark-grey) / 0.9);
   }
   &:focus,
   &:hover {
      border: 1.5px solid hsl(var(--clr-pm-cyan));
   }
   @include m(error) {
      border: 1.5px solid hsl(var(--clr-sd-bright-red));
      &:focus,
      &:hover {
         border: 1.5px solid hsl(var(--clr-sd-bright-red));
      }

      @include e(msg) {
         font-size: 0.8rem;
         color: hsl(var(--clr-sd-bright-red));
      }
   }
   @include m(text-area) {
      padding-bottom: 2.5rem;
      resize: none;
   }
}
