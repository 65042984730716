@use 'abstracts' as *;

.cta {
   margin-block: 7rem;
   @include m(details) {
      margin-block: var(--cta-details);
   }
   @include e(body) {
      @include mq(max-width, tablet) {
         grid-template-columns: 1fr;
         &,
         & > :first-child {
            display: grid;
            justify-items: center;
         }
         & .title--sd {
            width: 70%;
         }
      }
      @include mq(min-width, tablet) {
         grid-template-columns: auto 1fr auto;
         align-items: center;
         & .title--sd {
            max-width: 350px;
         }
      }
      @include e(line) {
         @include mq(min-width, tablet) {
            height: 1px;
            width: 100%;
            border-top: var(--thin-line);
         }
      }
   }
}
