@use 'abstracts' as *;

.footer {
   @include m(pm) {
      padding-block: var(--ct-padding-footer);
      @include e(body) {
         @include mq(max-width, tablet) {
            display: grid;
            place-items: center;
            gap: 1rem;
         }
         @include mq(min-width, tablet) {
            @include flexy($just: space-between, $align: center);
         }
      }
   }
}
