@use 'abstracts' as *;

.hero {
   @include m(home) {
      margin: 1rem 0 7rem;

      @include e(body) {
         grid-template-columns: 1fr;
         @include mq(min-width, tablet) {
            grid-template-columns: repeat(5, 1fr);
            @include bg-img(
               '../assets/images/homepage/desktop/hero-2.jpg',
               $position: -220px center,
               $size: 130%
            );

            @include e(img) {
               grid-column: 1 / -1;
               grid-row: 1 / -1;
               opacity: 0;
            }

            @include e(txt) {
               grid-column: 3 / 6;
               grid-row: 1 / -1;
               align-self: flex-end;
               padding: 1rem 1rem 0 0;
               @include flexy($dir: column, $align: flex-end);
               & .title {
                  max-width: 500px;
                  margin-bottom: 2rem;
                  text-align: right;
               }

               @include mq(min-width, desktop) {
                  grid-column: 3 / -1;
               }
            }
         }
         @include mq(min-width, desktop) {
            grid-template-columns: repeat(4, 1fr);
         }
      }
   }

   @include m(details) {
      margin-bottom: var(--details-hero-ct);
   }
}
