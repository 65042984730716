@use 'abstracts' as *;

.title {
   margin-bottom: 2rem;
   // @include m(pm) {
   // }

   // @include m(sd) {
   // }
}
