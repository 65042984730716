@use 'abstracts' as *;

.ct-wrapper {
   // outline: 1px solid hsl(var(--clr-pm-dark-grey));
   width: 85%;
   max-width: 1110px;
   margin-inline: auto;

   @include mq(min-width, tablet) {
      width: 90%;
   }

   @include e(offset) {
      max-width: 1015px;
   }
}
