@use 'abstracts' as *;

.social-list {
   @include flexy($align: center);
   gap: 1rem;
   @include e(item) {
      & > a {
         &:hover .social-icon {
            fill: hsl(var(--clr-pm-cyan));
         }
      }
   }
}

.social-icon {
   transition: fill var(--btn-hover-transition);
}
