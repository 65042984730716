@use 'abstracts' as *;

.skip-to-content {
   @include position(absolute, $z-index: 9999) {
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%);
   }
   transition: transform var(--hover-transition);

   &:hover,
   &:focus {
      transform: translate(-50%, 0);
   }
}
