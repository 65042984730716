@use 'abstracts' as *;

.form {
   @include e(fieldset) {
      margin-bottom: 1.3rem;
   }

   @include e(label) {
      display: block;
      margin-bottom: 1rem;
   }
}
